import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,_vm._g(_vm._b({staticClass:"app-card-code"},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c(VSlideXReverseTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCodeCollapsed),expression:"isCodeCollapsed"}]},[_c(VFadeTransition,{attrs:{"mode":"out-in"}},[_c(VIcon,{key:_vm.copyDynamicIcon,attrs:{"size":"21"},on:{"click":_vm.copyActiveCode}},[_vm._v(" "+_vm._s(_vm.copyDynamicIcon === 'copy' ? _vm.icons.mdiContentCopy : _vm.icons.mdiCheck)+" ")])],1)],1)]),_c(VIcon,{staticClass:"ms-4",attrs:{"dense":"","color":_vm.isCodeCollapsed ? 'primary' : null},on:{"click":function($event){_vm.isCodeCollapsed = !_vm.isCodeCollapsed}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCodeTags)+" ")])],1),_c('div',{staticClass:"demo-content"},[_vm._t("default")],2),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCodeCollapsed),expression:"isCodeCollapsed"}]},[_c(VDivider),_c(VCardText,[_c('prism',{attrs:{"language":_vm.codeLanguage}},[_vm._v(" "+_vm._s(_vm.code)+" ")])],1),_c(VSnackbar,{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.showSnackbarCopyNotSupported = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbarCopyNotSupported),callback:function ($$v) {_vm.showSnackbarCopyNotSupported=$$v},expression:"showSnackbarCopyNotSupported"}},[_vm._v(" Copy isn't supported on this device/browser. ")]),_c(VSnackbar,{attrs:{"color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.showSnackbarCopiedSuccessfully = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbarCopiedSuccessfully),callback:function ($$v) {_vm.showSnackbarCopiedSuccessfully=$$v},expression:"showSnackbarCopiedSuccessfully"}},[_vm._v(" Code Copied Successfully ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }